.franchisee-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background: #FFF;
    overflow: hidden;
}

.franchisee-head {
    padding-top: 1rem;
    background-image: linear-gradient(-180deg,rgb(102, 110, 232)10%,transparent );
    color: #FFF;
    height: 40vh;
    width: 100vw;
    font-size: 1rem;
}

.franchisee-desc-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.franchisee-head-desc {
    padding: 1rem;
}

.franchisee-desc-content {
    margin-top: 0.5rem;
    font-size: 0.7rem;
    color: rgba(255,255,255,0.5);
}

.franchisee-work-title {
    display: flex;
    color: #FFF;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
}

.org-card {
    position: absolute;
    box-shadow: 0px 2px 4px 2px rgb(7 17 27 / 12%);
    width: 90vw;
    height: 15vh;
    top: 22vh;
    background-color: #FFF;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
}

.org-cover {
    width: 2rem;
    height: 2rem;
    margin: 1rem;
}

.org-cover img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.org-card .org-name {
    font-size: 1rem;
    font-weight: 700;
}


/* 功能列表 */
.franchisee-btn-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 1rem;
    width: 100vw;
    position: absolute;
    top: 9rem;
}

.franchisee-btn-item {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 4px 2px rgb(7 17 27 / 12%);
    width: 8rem;
    height: 8rem;
    margin-bottom: 0.6rem;
    border-radius: 0.5rem;
    background-color: #FFF;
    padding: 0.5rem;
}

.franchisee-btn-cover {
    display: flex;
    width: 3rem;
    height: 3rem;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    margin-bottom: 1rem;
}

.franchisee-btn-cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.franchisee-btn-name {
    font-size: 0.8rem;
    font-weight: 700;
    color: #222;
    margin-bottom: 0.5rem;
}

.franchisee-btn-desc {
    color: #999;
    font-size: 0.6rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.franchisee-container .back-tabs {
    position: absolute;
    top: 1rem;
    right: 1rem;
    margin: 0;
    padding: 0;
    font-size: 1rem;
}