html {
    font-size: 20px;
    font-size: 5.33333vw
}

@media screen and (max-width:320px) {
    html {
        font-size: 17.06667px
    }
}

@media screen and (min-width:540px) {
    html {
        font-size: 28.8px
    }
}

.login-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
    background: #FFF;
}

.company-name {
    position: relative;
    color: #FFF;
    font-weight: 600;
    font-size: 1.5rem;
}

.login-card {
    width: 40vw;
    margin-bottom: 5rem;

}

.login-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 60vw;
    background-color: rgb(102, 110, 232);
}
.login-form{
    width: 80%;
    margin-left: 10%;
}
.login-company-logo {
    width: 10rem;
    height: 5rem;
}

.login-company-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.copy-right-text {
    font-size: 0.8rem;
    color: #999;
    margin-bottom: 1rem;
}

.company-product {
    color: #FFF;
    font-size: 0.9rem;
    font-weight: 700;
}

.login-btn-card {
    display: flex;
    justify-content: center;
}
