body {
    background-color: #e0e0df !important;
}

.index {
   
   
    height: 100vh;
  
}
.title-span{
        font-size: 16rpx;
    }
    .ant-segmented-group{
       flex-wrap: wrap;
    }

    .ant-segmented-thumb{
        background: none !important;
        box-shadow: none !important;
        }

.food_title {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-between;
    color: black;
    flex: 0 0 auto;
}

.food_title_text {
    background-color: white;
    margin: 5px 0px;
    padding: 20px;

    flex: 4;
}


.order_tj {
    width: 100%;
    margin: 5px 0px;
    padding: 5px;
    background-color: white;
    align-items: flex-start;
    flex: 2;
    font-size: 16px;
    font-weight: bold;
    color: rgba(2, 0, 0, 1);
}

.order_tj>div {
    position: relative;
    top: 25px;
    display: inline;
    margin: 10px;


}
.ant-select-selection-item{
    font-size: 28px;

}

.btn {


  
    margin: 15px;

}

.top_right {
    width: 100%;
    margin: 5px 0px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: white;
    align-items: flex-end;
    flex: 1;
    font-size: 20px;
    font-weight: lighter;
    color: rgba(2, 0, 0, 0.575);
}


.food-body {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    flex-grow: 1;
    height: 90.5vh;
    overflow: hidden;
    text-align: center;
    background-color:#efefef;
    border: 1px solid #ebedf0;
    border-radius: 2px;
    flex: 0 0 auto;



}

.food_left {
    width: 15vw;
    min-width: 15vw;
    background-color: white;
    margin-left: 10px;
    flex-grow: 0;
    flex-shrink: 0;
    overflow-y: scroll;
}

.food_left_title {
    display: flex;
    align-items: center;
    height: 10vh;
    text-align: center;
    margin: 5px;
}

.food_left_title_text {
    margin: auto;
    width: 100%;
}

.food_left_title_selected {
    background-color: #108ee9;
    color: blanchedalmond;
    box-shadow: 4px 4px 4px lightgrey;
    
}

.ant-card-cover {
    margin-top: 1px !important;
    margin-right: 1px !important;
    margin-left: 1px !important;
}



.ant-card-bordered {
    border: 1px solid #edf2f7 !important;
}
.ant-segmented  .ant-segmented-item-selected{
    background-color: #108ee9;
    color: #fff;
}
.food_center {
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    background-color: white;
    overflow-y: scroll;
    height: 100%;
    margin: 20px;
    flex: 5;
    border-radius: 15px;
}
.goods-contain{
  
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  
}
.food_item {
    width: 20%;
    margin: 0px 5px;
    flex-shrink: 0;
    background-color: white;
}

.food_item_empty {
    width: 20%;
    height: 0;
}


.food_right {
    background-color: white;
    padding: 5px;
    height: 100%;
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 2;
   margin: 20px;
   border-radius: 15px;
}

.Enter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
 
}

.wltypetitle {
    font-size: 25px;
    font-weight: bold;
    color: black;
    margin-bottom: 5px;

}

.ant-tag {
    height: 50px !important;
    line-height: 50px !important;
    text-align: center !important;
    font-size: 18px !important;
    margin: 10px !important;
}

.ant-notification-notice-message {
    color: cornflowerblue;
}



.ant-list-item-extra {
    margin: auto;
}

.ant-list-pagination {
    display: flex;
    justify-content: center;
}

.ant-list-items {
    width: 95%;
}

@media screen and (max-width: 450px) {
    .ant-card-cover {
        display: none;
    }

    .food_item {
        width: 100%;
        margin: 5px;
        height: 28vh;
    }

    .food_title {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;
        color: black;
        font-weight: bold;
        font-size: 30px;
        flex: 0 0 auto;
    }


}


/* 购物车样式 */
.car-goods-list {
    flex-grow: 1;
    list-style: none;
    padding: 0;
    margin: 0;
}

.car-goods-item {
    display: flex;
}

.card-goods-cover {
    flex-shrink: 0;
    margin-right: 15px;
}

.car-goods-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.goods-info-botom,
.goods-info-price,
.goods-info-count {
    display: flex;
    align-items: center;
}

.goods-info-price {
    width: 60%;
}

.goods-info-count {
    width: 40%;
    justify-content: space-between;
}

.goods-price-vip {
    font-size: 18px;
    font-weight: 700;
    color: #F84352;
}

.goods-price-common {
    font-size: 12px;
    color: #999;
    margin-left: 10px;
}

.shop-money {
    display: flex;
    align-items: center;
    box-shadow: 2px 4px 8px rgb(7 17 27 / 27%);
    padding: 5px 20px;
    box-sizing: border-box;
    position: relative;
    bottom: 30px;
}

.shop-money-btn {
    margin-left: auto;
}

.shop-money-rmb {
    font-size: 24px;
    margin-left: 15px;
    color: #F84352;
    font-family: monospace;
    font-weight: 900;
}

.clear-car-goods {
    width: 120px;
    margin-left: auto;
    margin-bottom: 10px;
}