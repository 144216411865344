.device-bom-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: #FFF;
    overflow: hidden;
}

.statistics-head-card {
    height: 5rem;
    flex-shrink: 0;
}

.device-bom-table {
    flex-grow: 1;
    overflow: auto;
}

.stock-admin-btns {
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;
    height: 3rem;
}

.stock-admin-btns .stock-btn-item {
    margin-left: 0.5rem;
}

.device-bom-container  .statistics-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
    color: #222;
}

.statistics-head-btn {
    width: 50%;
    border-radius: 1rem !important;
    height: 2rem !important;
}

.stock-admin-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.8rem;
    font-weight: 700;
    color: #222;
}