@font-face {
    font-family: electronicFont;
    src: url('../assets/font/DS-DIGIT.TTF')
}

.order-container {
    width: 100vw;
    height: 100vh;
    font-family: '微软雅黑';
    background-image: url('../assets/images/bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
    color: #daf9ff;
}

.order-header {
    position: relative;
    height: 15vh;
    margin-bottom: 1vh;
    background-image: url('../assets/images/topbg.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.company-logo {
    position: absolute;
    width: 8vw;
    height: 5vh;
    left: 2vw;
    top: 0;
}

.company-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.company-desc {
    font-size: calc(4vh);
    font-weight: 700;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
}

.now-date {
    position: absolute;
    right: 1vw;
    top: 0.5vh;
    font-size: 3vh;
    color: rgb(23, 225, 239);
    font-family: electronicFont;
}

.order-main {
    position: relative;
    width: 100vw;
    height: 84vh;
    display: flex;
    justify-content: space-evenly;
    box-sizing: border-box;
}

.order-list-item {
    position: relative;
    width: 49%;
    height: 99%;
    box-sizing: border-box;
    overflow-y: scroll;
    background-image: url('../assets/images/backhgound-box.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.in-product-head {
    position: absolute;
    top: 7.5vh;
    left: 1vw;
    color: rgb(23, 225, 239);
}

.comple-product-head {
    position: absolute;
    top: 7.5vh;
    right: 21.5vw;
    color: #F84352;
}

.info-card {
    width: 28vw;
    height: 7vh;
    background-image: url('../assets/images/info-card.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    text-align:center;
    line-height: 8vh;
    font-size:calc(3vh);
}

.info-card-text {
    position: absolute;
    font-size: 0.3rem;
    top: 2.5vh;
    left: 2vw;
}

.product-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2vw;
    margin-bottom: 1vh;
    background: -webkit-linear-gradient(left, rgba(255,255,255,0.1), rgba(255,255,255,0.5), rgba(255,255,255,0.1));
}

.product-name {
    font-size: calc(4vh);
    display: flex;
    flex-direction:row;
    justify-content:space-between
}

.product-status {
    font-size: calc(4vh);
    margin-left: auto;
}

.product-status span {
    font-family: electronicFont;
}

.pro-in-list .product-name {
    color: #F84352;
}

.animation-enter-1 {
    animation: bounce-in-top 1.1s both;
}

@keyframes bounce-in-top {
    0% {
        transform: translateY(-500px);
        animation-timing-function: ease-in;
        opacity: 0;
    }

    38% {
        transform: translateY(0);
        animation-timing-function: ease-out;
        opacity: 1;
    }

    55% {
        transform: translateY(-65px);
        animation-timing-function: ease-in;
    }

    72% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }

    81% {
        transform: translateY(-28px);
        animation-timing-function: ease-in;
    }

    90% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }

    95% {
        transform: translateY(-8px);
        animation-timing-function: ease-in;
    }

    100% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
}

.animation-enter-2 {
    animation: slide-in-blurred-right 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

@keyframes slide-in-blurred-right {
    0% {
        transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
        transform-origin: 0% 50%;
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        transform: translateX(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
    }
}

.store-list {
    font-size: 40px;
}


.store-item-head {
    color: #daf9ff;
}

.store-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 50px;
    margin-bottom: 1vh;
}
