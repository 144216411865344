


.ant-layout-header{
   
    height: 180px;
    background-color: white;
}
.ant-layout-footer{
    padding: 60px 0;
    background-color: #4E4E4E;
    background-size: cover;
}
.ant-layout-content{
   min-height: 200px;
}
