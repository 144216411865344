.loading-card {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 9999;
}

.loading-iconfont {
    position: absolute;
    z-index: 10000;
}