.statistics-container {
    position: relative;
    background-color: #FFF;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
    box-sizing: border-box;
}

.statistics-head {
    display: flex;
    align-items: center;
    position: sticky;
    height: 3rem;
    line-height: 3rem;
    top: 0;
    font-size: 0.9rem;
    font-weight: 700;
    padding-left: 1rem;
    background-color: #FFF;
    z-index: 999;
    color: #222;
}

.line-chart {
    padding-left: 0.5rem;
    margin: 0 auto;
}

.material-pie, .formula-pie {
    margin: 0 auto 1rem auto;
}

.equipment-order-data {
    position: absolute;
    top: 16.5rem;
    background-color: rgb(102, 110, 232);
    color: #FFF;
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    width: 16rem;
    left: 50%;
    transform: translateX(-50%);
}

.equipment-order-item {
    position: relative;
    text-align: center;
    width: 4rem;
}

.equipment-order-item:nth-child(1)::after, .equipment-order-item:nth-child(2)::after {
    position: absolute;
    right: -0.5rem;
    top: 50%;
    content: '';
    width: 0.1rem;
    height: 1.5rem;
    background-color: #FFF;
    transform: translateY(-50%);
}

.equi-item-top {
    font-size: 0.8rem;
    margin-bottom: 0.1rem;
}

.equi-item-bottom {
    font-size: 0.8rem;
}

.material-title {
    margin-top: 2.5rem;
    font-size: 1rem;
    font-weight: 100;
    padding-left: 1rem;
}


.back-last {
    display: flex;
    margin-right: 1rem;
    align-items: center;
}

.back-head-card {
    margin-right: 1rem;
}

.back-tabs {
    margin-left: auto;
    font-size: 0.8rem;
    margin-right: 1rem;
}

.start-end-time {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 0.5rem;
}


.tab-list-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 98%;
    margin: auto;
}

.tab-list-item {
    width: 50%;
    text-align: center;
    height: 8vh;
    line-height: 8vh;
    font-size: 0.8rem;
    border-radius: 2rem;
}

.tab-list-activity {
    background-color: #1677ff;
    color: #FFF;
}

.echart-pagenation {
    display: flex;
    width: 90vw;
    justify-content: flex-end;
    margin-bottom: 1rem;
    font-size: 0.8rem;
}


.device-change {
    font-size: 0.7rem;
    height: 3rem;
    line-height: 3rem;
}